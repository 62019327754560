import axios from "axios";
import React from "react";
import { getLocalStorageToken } from "../utils/CommonFunction";
import { toast } from "react-toastify";
import CommonMessage from "../utils/CommonMessage";

const baseURL = process.env.REACT_APP_APIURL;

// const serialize = function(obj:any) {
//   return new URLSearchParams(obj).toString()
// }

// const confg = async (data) => {
//   // console.log('config = ',data)
//   try {
//     const axiosConfig = {
//       method: data?.method,
//       url: baseURL + data?.url,
//       headers: data?.headers,
//       params: data?.params,
//     };

//     if (data.params) {
//       axiosConfig.params = serialize({ ...axiosConfig.params, ...data?.params });
//     }

//     if (data?.headers) {
//       axiosConfig.headers = { ...axiosConfig.headers, ...data?.headers };
//     }

//     if (data?.data) {
//       axiosConfig.data = data?.data;
//     }
//     const res = await axios(axiosConfig);
//     return res?.data;
//   } catch (error) {
//     toast.error(error?.response?.data?.message || "something went wrong")
//       throw Error(error)
//   }
// };

// const errorLoop = () => {
//   for (const property in object) {
//     console.log(`${property}: ${object[property]}`);
//     toast.error(`${property}`)
//   }
// }

// const confg = async (data) => {
//   try {
//     return await axios({
//       method: data?.method,
//       url: baseURL + data?.url,
//       headers: data?.headers,
//       params: data?.params,
//       data: data?.data,
//     });
//   } catch (error) {
//     console.log("main Api =", error);
//     if (
//       error?.response?.data?.message &&
//       (typeof error?.response?.data?.message == "object" ||
//         Array.isArray(error?.response?.data?.message))
//     ) {
//       for (const property in error?.response?.data?.message) {
//         toast.error(`${error?.response?.data?.message[property]}`);
//       }

//     } else {
//       toast.error(error?.response?.data?.message || "something went wrong");
//     }
//     if (error?.response?.status === 401) {
//       setTimeout(() => {
//         localStorage.removeItem("permission");
//         localStorage.removeItem("Login");
//         window?.location?.replace("/");
//       }, 2000);
//     }
//     throw new Error(error);
//   }
// };

const errorMessagesDisplayed = new Set();

const confg = async (data) => {
  try {
    const response = await axios({
      method: data?.method,
      url: baseURL + data?.url,
      headers: data?.headers,
      params: data?.params,
      data: data?.data,
    });
    return response;
  } catch (error) {
    // console.log("main Api =", error);

    // Check if the error message has already been displayed
    const errorMessage =
      error?.response?.data?.message || "something went wrong";
    if (!errorMessagesDisplayed.has(errorMessage)) {
      errorMessagesDisplayed.add(errorMessage);
      if (
        error?.response?.data?.message &&
        (typeof error?.response?.data?.message == "object" ||
          Array.isArray(error?.response?.data?.message))
      ) {
        for (const property in error?.response?.data?.message) {
          toast.error(`${error?.response?.data?.message[property]}`);
        }
      } else {
        toast.error(errorMessage);
      }
    }

    if (error?.response?.status === 401) {
      setTimeout(() => {
        localStorage.removeItem("permission");
        localStorage.removeItem("Login");
        window?.location?.replace("/");
      }, 2000);
    } else {
      errorMessagesDisplayed.clear();
    }

    throw new Error(error);
  }
};

export const LoginApi = async (data) => {
  let config = {
    method: "post",
    url: "user/login",
    data: data,
  };

  let Token = await confg(config);
  return Token;
};

export const RegisterApi = async (data) => {
  let config = {
    method: "post",
    url: "user/signup",
    data: data,
  };
  let Token = await confg(config);
  return Token;
};

export const FogotPWDApi = async (data) => {
  let config = {
    method: "post",
    url: "user/forgot/password",
    data: data,
  };
  let Token = await confg(config);
  return Token;
};

export const ResetPWDApi = async (data) => {
  let config = {
    method: "post",
    // url: `password-reset-confirm/${id}/${token}`,
    url: `user/reset/password`,
    data: data,
  };
  let Token = await confg(config);
  return Token;
};

export const VerifiedEmailApi = async (IdToken) => {
  let config = {
    // method: "put",
    // url: `activate?token=` + IdToken,
    method: "get",
    url: `email/u/verification?type=email&token=${IdToken}`,
    // data: { token: IdToken },
  };
  let Token = await confg(config);
  return Token;
};

// Notification Api
export const GetALLNotificationApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-notifications",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};

export const NotificationCountApi = async () => {
  let token = getLocalStorageToken("Login");
  return await axios({
    method: "get",
    url: baseURL + "get-notifications-count",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  // let config = {
  //   method: "get",
  //   url: "get-notifications-count",
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   }
  // };
  // let Token = await confg(config);
  // return Token;
};

export const NotificationReadApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "read-unread-notification",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Change Password
export const ChangePasswordApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "user/change_password",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

export const UpdateProfileApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "user/update_profile",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

export const GetProfileApi = async () => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "user/profile",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

// Vendors Api
export const GetAllVendorApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-vendors",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetVendorByIdApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: `get-vendor/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AddVendorApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-vendor",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateVendorApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-vendor",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Contract Api
export const AddContractApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-vendor-contract",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

export const DeleteContractApi = async (id, venderId) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "delete",
    url: `delete-vendor-contract/${id}?vendor_id=${venderId}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
// Upload Invoice
export const InvoiceUploadApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "upload-invoice",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
// File Upload Get S3-Url
export const FileUploadApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "upload",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

export const FileInvoiceUploadApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "upload-invoice-file",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

export const InvoiceCalenderGetAllApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-calendar",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};

export const InvoiceGetByIdApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: `get-invoice/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

export const InvoiceGetAllApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-invoices",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};

export const InvoiceTimeLineGetAllApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-timeline",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};

export const GetAllInvoiceByVendorId = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-invoices",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};

export const UpdateInvoiceApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-invoice",
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  let Token = await confg(config);
  return Token;
};

export const InvoiceApproveRejectApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "approve-or-reject-invoice",
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  let Token = await confg(config);
  return Token;
};

// Csv File Upload
export const CSVFileUploadApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "upload-vendor",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Configuration Api
export const AddConfigurationApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-configuration",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const GetAllConfigurationApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-configurations",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetConfigurationByIdApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: `get-configuration/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const DeleteConfigurationByIdApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "delete",
    url: `delete-configuration/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateConfigurationApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-configuration",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Roles Permission Api
export const GetAllPermissionApi = async () => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-permissions",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetAllRoleApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-roles",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetRoleByIdApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: `get-role/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AddRoleApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-role",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateRoleApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-role",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const DeleteRoleApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "delete",
    url: `delete-role/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

// Users webFront Api
export const GetAllUsersApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-users",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetUsersByIdApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: `get-user/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AddUserApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-user",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateUserApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-user",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const SendEmailUserApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "reg/email/u/verification",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Servey
export const ServeyApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "register-contact",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data,
  };
  let token = await confg(config);
  return token;
};

// Contact Us
export const ContactUsApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "contact",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data,
  };
  let token = await confg(config);
  return token;
};

// Dashboard Api's
export const Top10Api = async () => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-top-10-vendors",
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
export const Top10VolumeApi = async () => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-top-10-vendors-volume",
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};

export const RecentInvoiceApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-recent-invoices",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};

export const AverageDaysApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-finance-payment-terms-graph",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const AverageDaysVolumeApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-volume-payment-terms-graph",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const RunRateVolumeApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-volume-run-rate-graph",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const RunRateFinanceApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-finance-run-rate-graph",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const TotalSpendingApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-total-spending",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const NextPaymentApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-next-payment",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const InvoiceAnalyticVolumeApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-volume-graph",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const InvoiceAnalyticFinanceApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-finance-graph",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const InvoiceScheduledApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "schedule-invoice",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
// Email Configurations
export const EmailConfigurationGetAllApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-imap-settings",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const UpdateEmailConfigurationApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-imap-setting",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
export const DeleteEmailConfigurationApi = async (id) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "delete",
    url: `delete-imap-setting/${id}`,
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
export const SyncEmailConfigurationApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "read-email",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};

// Category API
export const GetAllCategoryApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-categories",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const AddCategoryApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-category",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
export const UpdateCategoryApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-category",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};

// Payment Planner API
export const GetAllPaymentPlannerApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-payment-limits",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const AddPaymentPlannerApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-payment-limit",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
export const UpdatePaymentPlannerApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-payment-limit",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
export const DetailsPaymentPlannerApi = async ({ params }) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-invoice-payment-planner",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};

// Two Way Auth API
export const GenerateQrCodeApi = async () => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "user/generate-2fa-secret",
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
export const QrCodeVerifyApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "user/verify-otp",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
export const QrCodeDisibleApi = async (data) => {
  var Token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "user/disable-2fa",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};

// Two Way Auth Api
export const TwoWayOtpAuthApi = async (data) => {
  var Token = getLocalStorageToken("TwoWayAuthLogin");
  // console.log("Token = = ", Token);
  let config = {
    method: "post",
    url: "user/login_step_2",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
// Reports Module Api
export const GetALLInvoiceReportApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-invoices",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetAllTop10VendorReportApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-top-10-vendors",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetAllInvoiceStatusReportApi = async ({ params }) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-invoices?progress_status=scheduled/received/approved/etc...&created_at_from=2024-01-01&created_at_to=2024-02-01",
    // url: "get-all-invoices",
    headers: {
      Authorization: "Bearer " + token,
    },
    // params: {
    //   ...params,
    // },
  };
  let Token = await confg(config);
  return Token;
};

// Logo Api
export const UpdateLogoApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: "update-setting",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

export const GetLogoApi = async () => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-setting",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

// Contact Supports
export const ContactSupportApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "support",
    headers: {
      Authorization: "Bearer " + token,
    },
    data
  };
  let Token = await confg(config);
  return Token;
};

// Add Amount Range
export const AddAmountRangeApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "add-amount-range",
    headers: {
      Authorization: "Bearer " + token,
    },
    data
  };
  let Token = await confg(config);
  return Token;
};

export const GetAllAmountRangeApi = async ({params}) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: "get-all-amount-ranges",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params
    }
  };
  let Token = await confg(config);
  return Token;
};

export const DeleteAmountRangeApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "delete",
    url: `delete-amount-range/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

export const AmountAttachedApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: 'add-invoice-approve-permission',
    headers: {
      Authorization: "Bearer " + token,
    },
    data
  };
  let Token = await confg(config);
  return Token;
};

export const GetAllApproverPermissionApi = async ({params}) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: 'get-all-invoice-approve-permissions',
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params
    }
  };
  let Token = await confg(config);
  return Token;
};

export const GetAllInvoiceApproverPermissionApi = async () => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: 'get-invoice-approve-permission-by-user',
    headers: {
      Authorization: "Bearer " + token,
    }
  };
  let Token = await confg(config);
  return Token;
};

export const GetAllInvoicePermissionsApi = async ({params}) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: 'get-all-invoice-approve-permissions-report',
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params
    }
  };
  let Token = await confg(config);
  return Token;
};
// Invoice Logs Api
export const GetAllInvoiceLogsApi = async ({params}) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: 'get-all-invoice-logs',
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params
    }
  };
  let Token = await confg(config);
  return Token;
};
export const GetByIdInvoiceLogsApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: `get-invoice-log/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateInvoiceLogsApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: 'update-invoice-log',
    headers: {
      Authorization: "Bearer " + token,
    },
    data
  };
  let Token = await confg(config);
  return Token;
};
export const DeleteInvoiceLogsApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "delete",
    url: `delete-invoice-log/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetInvoiceLogImageApi = async (id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "get",
    url: 'get-in-process-invoice-logs',
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateUserStatusApi = async (data,id) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "put",
    url: `update-user-status/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
    data:data,
  };
  let Token = await confg(config);
  return Token;
};
