import axios from "axios";
import React from "react";
import { getLocalStorageToken } from "../utils/CommonFunction";
import { toast } from "react-toastify";

const baseURL = process.env?.REACT_APP_APIURL?.replace("api/v1", "admin");

// const confg = async (data) => {
//   try {
//     return await axios({
//       method: data?.method,
//       url: baseURL + data?.url,
//       headers: data?.headers,
//       params: data?.params,
//       data: data?.data,
//     });
//   } catch (error) {
//     if (
//       error?.response?.data?.message &&
//       (typeof error?.response?.data?.message == "object" ||
//         Array.isArray(error?.response?.data?.message))
//     ) {
//       for (const property in error?.response?.data?.message) {
//         // console.log(`${property}: ${error?.response?.data?.message[property]}`);
//         toast.error(`${error?.response?.data?.message[property]}`);
//       }
//       //toast.error(error?.response?.data?.message?.email?.[0] || "something went wrong")
//     } else {
//       toast.error(error?.response?.data?.message || "something went wrong");
//     }
//     if (error?.response?.status === 401) {
//       // localStorage.removeItem('permission');
//       setTimeout(() => {
//         localStorage.removeItem("AdminLogin");
//         window.location?.replace("/admin/login");
//       }, 2000);
//       // <CommonMessage message={error?.response?.data?.message} />
//     }
//     throw Error(error);
//   }
// };

const errorMessagesDisplayed = new Set();

const confg = async (data) => {
  try {
    const response = await axios({
      method: data?.method,
      url: baseURL + data?.url,
      headers: data?.headers,
      params: data?.params,
      data: data?.data,
    });
    return response;
  } catch (error) {
    // console.log("main Api =", error);

    // Check if the error message has already been displayed
    const errorMessage =
      error?.response?.data?.message || "something went wrong";
    if (!errorMessagesDisplayed.has(errorMessage)) {
      errorMessagesDisplayed.add(errorMessage);
      if (
        error?.response?.data?.message &&
        (typeof error?.response?.data?.message == "object" ||
          Array.isArray(error?.response?.data?.message))
      ) {
        for (const property in error?.response?.data?.message) {
          toast.error(`${error?.response?.data?.message[property]}`);
        }
      } else {
        toast.error(errorMessage);
      }
    }

    if (error?.response?.status === 401) {
      setTimeout(() => {
        localStorage.removeItem("AdminLogin");
        window.location?.replace("/admin/login");
      }, 2000);
    } else {
      errorMessagesDisplayed.clear();
    }

    throw new Error(error);
  }
};

export const AdminLoginApi = async (data) => {
  let config = {
    method: "post",
    url: "login",
    data: data,
  };

  let Token = await confg(config);
  return Token;
};

// User Api
export const AdminGetAllUsersApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-users",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetUserByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: `get-user/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateUserApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-user",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Admin User Api
export const GetAllAdminUserApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-users",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetAdminUserByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: `get-user/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateAdminUserApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-user",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AddAdminUserApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "post",
    url: "add-user",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminSendEmailUserApi = async (data) => {
  let token = getLocalStorageToken("Login");
  let config = {
    method: "post",
    url: "reg/email/u/verification",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
// Sub Admin Api
export const GetAllSubAdminApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-admin",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetSubAdminByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: `get-admin/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AddSubAdminApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "post",
    url: "add-admin",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateSubAdminApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-admin",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Role Permission Api
// User Role Permission Api
export const AdminUserGetAllPermissionApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-permissions-user",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetAllRoleApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-roles",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminGetRoleByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: `get-role/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminAddRoleApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "post",
    url: "add-role",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminUpdateRoleApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-role",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const DeleteRoleApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "delete",
    url: `delete-role/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
// Sub Admin Role Permission Api
export const SubAdminGetAllPermissionApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-permissions-admin",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const GetAllSubAdminRoleApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-roles-admin",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

// Notification Api
export const AdminGetALLNotificationApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-notifications",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminNotificationCountApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-notifications-count",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminNotificationReadApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "read-unread-notification",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Change Password
export const AdminChangePasswordApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "change-password",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminUpdateProfileApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-profile",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminGetProfileApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-profile",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

// Configuration Api
export const GetALLAdminConfigurationApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-configurations",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminAddConfigurationApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "post",
    url: "add-configuration",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminGetConfigurationByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: `get-configuration/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminDeleteConfigurationByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "delete",
    url: `delete-configuration/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminUpdateConfigurationApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-configuration",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminConfigurationGetAllUsersApi = async () => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-users?limit=-1",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};

// Vendors Api
export const AdminGetAllVendorApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-vendors",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminGetVendorByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: `get-vendor/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminAddVendorApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "post",
    url: "add-vendor",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminUpdateVendorApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-vendor",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Contract Api
export const AdminAddContractApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "post",
    url: "add-vendor-contract",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};
export const AdminDeleteContractApi = async (id, venderId) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "delete",
    url: `delete-vendor-contract/${id}?vendor_id=${venderId}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
// Csv File Upload
export const AdminCSVFileUploadApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "post",
    url: "upload-vendor",
    headers: {
      Authorization: "Bearer " + token,
    },
    data,
  };
  let Token = await confg(config);
  return Token;
};

// Invoice Api
export const AdminInvoiceCalenderGetAllApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-invoice-calendar",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminArchivesInvoiceGetAllApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-invoices",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminInvoiceGetAllApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-invoices",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminInvoiceTimeLineGetAllApi = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-invoice-timeline",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminInvoiceGetByIdApi = async (id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: `get-invoice/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminGetAllInvoiceByVendorId = async ({ params }) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-invoices",
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params,
    },
  };
  let Token = await confg(config);
  return Token;
};
export const AdminUpdateInvoiceApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-invoice",
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  let Token = await confg(config);
  return Token;
};

export const AdminInvoiceApproveRejectApi = async (data) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "approve-or-reject-invoice",
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  let Token = await confg(config);
  return Token;
};

// Dashboard Graph Api
export const AdminInvoiceAnalyticVolumeApi = async () => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-invoice-volume-graph?type=monthly",
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
export const AdminInvoiceAnalyticFinanceApi = async () => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-invoice-finance-graph?type=monthly",
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
export const AdminTotalSpendingApi = async ({ params }) => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-invoice-total-spending",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const AdminNextPaymentApi = async ({ params }) => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-invoice-next-payment",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const AdminAverageDaysApi = async () => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-invoice-finance-avg-day-graph",
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
export const AdminTop10Api = async () => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-top-10-invoices",
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
export const AdminRecentInvoiceApi = async ({ params }) => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-recent-invoices",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};

export const AdminInvoiceScheduledApi = async (data) => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "schedule-invoice",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
// Email Configurations
export const AdminEmailConfigurationGetAllApi = async ({ params }) => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: "get-all-imap-settings",
    headers: {
      Authorization: "Bearer " + Token,
    },
    params: {
      ...params,
    },
  };
  let token = await confg(config);
  return token;
};
export const AdminUpdateEmailConfigurationApi = async (data) => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: "update-imap-setting",
    headers: {
      Authorization: "Bearer " + Token,
    },
    data: data,
  };
  let token = await confg(config);
  return token;
};
export const AdminDeleteEmailConfigurationApi = async (id) => {
  var Token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "delete",
    url: `delete-imap-setting/${id}`,
    headers: {
      Authorization: "Bearer " + Token,
    },
  };
  let token = await confg(config);
  return token;
};
// Report Invoice Logs Api
export const AdminGetAllInvoiceLogsApi = async ({params}) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "get",
    url: 'get-all-invoice-logs',
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      ...params
    }
  };
  let Token = await confg(config);
  return Token;
};
export const UpdateUserStatusApi = async (data,id) => {
  let token = getLocalStorageToken("AdminLogin");
  let config = {
    method: "put",
    url: `update-user-status/${id}`,
    headers: {
      Authorization: "Bearer " + token,
    },
    data:data,
  };
  let Token = await confg(config);
  return Token;
};
